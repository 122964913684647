<template>
  <div class="pt-6 pb-2">
    <v-data-iterator
      class="activity-iterator-wrap"
      :items="items"
      :items-per-page="pagination"
      hide-default-footer
    >
      <template v-if="!loading && items.length === 0" v-slot:no-data>
        <div
          class="no-data-wrap d-flex flex-column align-center justify-center pt-8 px-5"
        >
          <ph-vault :size="80" :color="'#bfbfbf'" />
          <block-text
            :text="
              $t('activities.table.noDataTitle', {
                dateFilterVal: formattedDateFilter,
              })
            "
            class="pt-3"
            align="center"
            color="primary"
            type="headline"
          />
          <block-text
            :text="$t('activities.table.noDataDescription')"
            class="pt-4"
            align="center"
            color="secondary"
            type="body-1"
          />
        </div>
      </template>

      <template v-slot:default="{ items }" v-if="items.length">
        <v-row>
          <v-col v-for="(item, itemIndex) in items" :key="itemIndex" cols="12">
            <v-card
              @click="toggleInfoDialog(item)"
              class="px-5 py-5 rounded-lg"
              :ripple="false"
              :class="
                item.status.toLowerCase() === 'completed'
                  ? 'completed'
                  : 'pending'
              "
            >
              <div class="d-flex align-center justify-space-between">
                <div class="d-flex align-center overflow-hidden mr-5">
                  <div
                    v-if="isMasterWallet(item.user)"
                    class="d-flex align-center"
                  >
                    <v-img max-width="40" :src="require('@/assets/logo.svg')" />
                  </div>
                  <div v-else class="d-flex align-center">
                    <v-avatar color="rgba(190, 191, 192, 0.25)" size="40">
                      <v-img
                        v-if="item.userImage"
                        max-width="40"
                        :src="item.userImage"
                      />
                      <ph-user
                        v-else
                        :size="24"
                        :color="'rgba(128, 128, 129, 1)'"
                      />
                    </v-avatar>
                  </div>
                  <div class="d-flex flex-column overflow-hidden pl-3">
                    <block-text
                      v-if="isMyCommunity(item.user, item.type)"
                      :text="$t('activities.table.myCommunity')"
                      color="primary"
                      type="body-2"
                      class="truncate-text"
                    />
                    <block-text
                      v-else
                      :text="item.user"
                      color="primary"
                      type="body-2"
                      class="truncate-text"
                    />
                    <div
                      v-if="item.status.toLowerCase() === 'completed'"
                      class="d-flex align-center mt-1"
                    >
                      <ph-check-circle
                        :size="16"
                        :color="$vuetify.theme.dark ? '#fff' : '#222'"
                      />
                      <block-text
                        :text="item.status"
                        color="primary"
                        type="caption"
                        weight="bold"
                        class="pl-1"
                      />
                    </div>
                    <div
                      v-else-if="item.status.toLowerCase() === 'pending'"
                      class="d-flex align-center mt-1"
                    >
                      <ph-arrows-clockwise
                        class="pending-icon"
                        :size="16"
                        :color="'#888'"
                      />
                      <block-text
                        :text="item.status"
                        color="secondary"
                        type="caption"
                        weight="bold"
                        class="pl-1"
                      />
                    </div>
                    <div
                      v-else-if="item.status.toLowerCase() === 'rejected'"
                      class="d-flex align-center mt-1"
                    >
                      <ph-x-circle :size="16" :color="'#888'" />
                      <block-text
                        :text="item.status"
                        color="secondary"
                        type="caption"
                        weight="bold"
                        class="pl-1"
                      />
                    </div>
                    <div
                      v-else-if="item.status.toLowerCase() === 'expired'"
                      class="d-flex align-center mt-1"
                    >
                      <ph-warning-circle :size="16" :color="'#888'" />
                      <block-text
                        :text="item.status"
                        color="secondary"
                        type="caption"
                        weight="bold"
                        class="pl-1"
                      />
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-column align-end">
                  <div class="d-flex align-center">
                    <block-text
                      :text="
                        item.amount > 0
                          ? '+' + item.amount.toFixed(2)
                          : item.amount.toFixed(2)
                      "
                      :color="
                        item.status.toLowerCase() === 'completed'
                          ? 'primary'
                          : 'secondary'
                      "
                      type="body-2"
                      weight="bold"
                    />
                    <div class="pl-2">
                      <v-img
                        class="ttu-coin-img rounded-circle"
                        width="20"
                        height="20"
                        :src="require('@/assets/ttuToken.svg')"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex align-center mt-4">
                <type-icon :type="item.type" class="mr-2" />
                <block-text
                  :text="item.type + ' /'"
                  color="secondary"
                  type="caption"
                />
                <block-text
                  :text="item.dateTime | convertToLocalDateShortMonth"
                  class="pl-1"
                  color="secondary"
                  type="caption"
                />
              </div>
              <v-divider
                v-if="
                  item.status.toLowerCase() === 'pending' &&
                  item.direction.toLowerCase() === 'income'
                "
                class="mt-5 pb-2"
              ></v-divider>
              <div
                v-if="
                  item.status.toLowerCase() === 'pending' &&
                  item.direction.toLowerCase() === 'income'
                "
                class="accept-reject-wrap d-flex align-center pt-4 pl-0"
                :class="
                  $vuetify.breakpoint.xsOnly ? 'justify-space-between' : ''
                "
              >
                <v-btn
                  :width="$vuetify.breakpoint.xsOnly ? 90 : 130"
                  :height="$vuetify.breakpoint.xsOnly ? 30 : 40"
                  text
                  :ripple="false"
                  :color="$vuetify.theme.dark ? '#fff' : '#222'"
                  plain
                  :opacity="1"
                  class="rounded-lg body-1 bold"
                  @click.stop="toggleRejectDialog(item)"
                  >{{ $t("activities.table.reject") }}</v-btn
                >
                <v-btn
                  :width="$vuetify.breakpoint.xsOnly ? 90 : 130"
                  :height="$vuetify.breakpoint.xsOnly ? 30 : 40"
                  text
                  :ripple="false"
                  :color="$vuetify.theme.dark ? '#fff' : '#222'"
                  plain
                  :opacity="1"
                  class="rounded-lg body-1 bold ml-4"
                  @click.stop="toggleAcceptDialog(item)"
                  >{{ $t("activities.table.accept") }}</v-btn
                >
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <v-col cols="12" v-if="loading">
      <v-skeleton-loader
        width="100%"
        class="pt-0"
        type="table"
        :types="{
          'table-row': 'table-cell@3',
          'table-heading': false,
          'table-thead': false,
          'table-tfoot': false,
        }"
      />
    </v-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dateConvertor from "@/mixins/dateConvertor";
import {
  PhUser,
  PhCheckCircle,
  PhArrowsClockwise,
  PhXCircle,
  PhWarningCircle,
  PhVault,
} from "phosphor-vue";
export default {
  components: {
    TypeIcon: () => import("@/components/TypeIcon"),
    PhUser,
    PhCheckCircle,
    PhArrowsClockwise,
    PhXCircle,
    PhWarningCircle,
    PhVault,
  },
  mixins: [dateConvertor],
  props: {
    items: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Number,
      required: true,
    },
    direction: {
      type: String,
      required: false,
      default: "",
    },
    last30: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  filters: {
    formatted(value) {
      if (typeof value === "string") return "90+";
      return value;
    },
  },
  computed: {
    ...mapGetters({
      user: "wallet/user",
      currentDateFilter: "wallet/currentDateFilter",
    }),
    formattedDateFilter() {
      return this.$options.filters.formatted(this.currentDateFilter);
    },
  },
  methods: {
    isMasterWallet(user) {
      return (
        user.toLowerCase() === "master wallet" ||
        user.toLowerCase() === "tatatu enterprise ltd (master wallet)"
      );
    },
    isMyCommunity(user, type) {
      return !user && type.toLowerCase() === "donations";
    },
    toggleInfoDialog(transaction) {
      let dialogTitle = "";
      let dialogIcon = "";
      switch (transaction.status) {
        case "Completed":
          dialogTitle = this.$t("dialog.transactionCompleted");
          dialogIcon = "PhCheckCircle";
          break;
        case "Pending":
          dialogTitle = this.$t("dialog.transactionPending");
          dialogIcon = "PhArrowsClockwise";
          break;
        case "Rejected":
          dialogTitle = this.$t("dialog.transactionRejected");
          dialogIcon = "PhXCircle";
          break;
        case "Expired":
          dialogTitle = this.$t("dialog.transactionExpired");
          dialogIcon = "PhWarningCircle";
          break;
        default:
          dialogTitle = this.$t("dialog.transactionCompleted");
          dialogIcon = "PhCheckCircle";
      }
      this.$eventHub.$emit("toggleDialog", {
        isOpen: true,
        headerIcon: dialogIcon,
        dialogPath: "components/Dialogs/TransactionInfoDialog",
        title: dialogTitle,
        payload: transaction,
      });
    },
    toggleRejectDialog(transaction) {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: true,
        dialogPath: "components/Dialogs/ConfirmDialog",
        payload: {
          dialogType: "reject",
          dialogIcon: "PhXCircle",
          transaction: transaction,
          text1: this.$t("dialog.rejectText1", {
            amount: transaction.amount.toFixed(2),
            user: transaction.user,
          }),
          text2: this.$t("dialog.rejectText2", {
            amount: transaction.amount.toFixed(2),
            user: transaction.user,
          }),
          ctaText: this.$t("dialog.confirm"),
          direction: this.direction,
        },
      });
    },
    toggleAcceptDialog(transaction) {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: true,
        dialogPath: "components/Dialogs/ConfirmDialog",
        payload: {
          dialogType: "accept",
          dialogIcon: "PhCheckCircle",
          transaction: transaction,
          text1: this.$t("dialog.acceptText1", {
            amount: transaction.amount.toFixed(2),
            user: transaction.user,
          }),
          text2: this.$t("dialog.acceptText2", {
            amount: transaction.amount.toFixed(2),
            user: transaction.user,
          }),
          ctaText: this.$t("dialog.confirm"),
          direction: this.direction,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-skeleton-loader ::v-deep .v-skeleton-loader__table-heading,
.v-skeleton-loader ::v-deep .v-skeleton-loader__table-thead,
.v-skeleton-loader ::v-deep .v-skeleton-loader__table-tbody,
.v-skeleton-loader ::v-deep .v-skeleton-loader__table-tfoot {
  background-color: transparent;
}

.v-skeleton-loader ::v-deep .v-skeleton-loader__table-heading,
.v-skeleton-loader ::v-deep .v-skeleton-loader__table-thead {
  padding: 0;
}

.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)::v-deep
  .v-btn__content {
  opacity: 1;
}
.activity-iterator-wrap {
  .v-card {
    background-color: var(--v-lightgrey-base);
    &.pending {
      background-color: var(--v-pendingBck-base);
    }
  }
  .v-card,
  .v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: $box-shadow-light;
  }

  .v-avatar svg {
    width: 24px;
    height: 24px;
  }

  .ttu-coin-img {
    box-shadow: $box-shadow-light;
  }

  .accept-reject-wrap {
    .v-btn {
      border: 1px solid var(--v-btnBorder-base);
    }
  }
  .no-data-wrap {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
  }
}
</style>
